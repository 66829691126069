<template>
  <div class="cookie-manager">
    <modal
      v-show="showModal"
      :show-modal="showModal"
      :styles="styles"
      @close="showModal = false"
    >

      <!-- .introduction -->
      <div slot="header" class="introduction">
        <h3 
          v-if="translations.introduction.title"
          class="introduction__title" 
          :style="styles.title" 
          >{{ translations.introduction.title }}</h3>
        <!-- eslint-disable-next-line -->
        <p class="introduction__text" v-if="translations.introduction.text" v-html="translations.introduction.text"></p>
      </div>
      <!-- /.introduction -->

      <!-- .body -->
      <div slot="body">
        <!-- .settings-view -->
        <div class="settings-view">
          <!-- .form-settings -->
          <div class="form-settings">
            <form-row
              v-for="(values, model) in availableCookieServices"
              :key="model"
              :model="model"
              :class="{ 'form-row--inactive': _isRequiredCookies(model) }"
              :services="services[model]"
              :options="values"
              :title="translations.settings.permissions[model].title"
              :text="translations.settings.permissions[model].text"
              :handle-checkbox="handleCheckbox"
              :links="cookieManagerConfig.links"
              :styles="styles"
            />
          </div>
          <!-- /.form-settings -->
        </div>
        <!-- /.settings-view -->
      </div>
      <!-- /.body -->

      <!-- .footer -->
      <template slot="footer">
        <aside class="cookie-manager__buttons">
          <button
            class="cookie-manager__button accept-cookie-settings"
            :style="styles.buttons.acceptAll"
            @click="handleAccept"
            >{{ translations.buttons.accept }}</button>
          <button
            class="cookie-manager__button save-cookie-settings"
            :style="styles.buttons.saveSettings"
            @click="saveSettings"
            >{{ translations.buttons.saveSettings }}</button>
        </aside>
        <ul class="cookie-manager__links">
          <li class="cookie-manager__link">
            <a 
              href="#" 
              :style="styles.link"
              data-no-swup
              @click.prevent="deleteCookies" 
              >{{ translations.buttons.deleteCookies }}</a>
          </li>
          <li 
            v-for="link in cookieManagerConfig.links" 
            :key="link.link" 
            class="cookie-manager__link"
            >
            <a :href="link.link" data-no-swup :style="styles.link">{{ link.text }}</a>
          </li>
        </ul>
      </template>
      <!-- /.footer -->
    </modal>

    <debug-permissions v-if="cookieManagerConfig.debug" :state="this" :cookie="savedCookie" />
  </div>
</template>

<script>
import CookieConsentApi from 'cookie-consent-api'
import {
  disableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'

import uuidv4 from '@assets/uuid.js'

import Modal from '@components/Modal/index.vue'
import FormRow from '@components/CookieManager/FormRow.vue'

import { defaultOptions } from '@components/CookieManager/defaultOptions.js'

import api from '@assets/api.js'

const options = () => {
  if (window.cookieManagerConfig) {
    return {
      ...defaultOptions,
      ...window.cookieManagerConfig,
    }
  }
  if (window.CookieManagerConfig) {
    // backwards compatibility
    return {
      ...defaultOptions,
      ...window.CookieManagerConfig,
    }
  }
  return defaultOptions
}

if (window) {
  window.cookieManagerConfig = options()
}

export default {
  components: {
    Modal,
    DebugPermissions: () => import(/* webpackChunkName: "DebugPermissions" */ '@components/CookieManager/DebugPermissions.vue'),
    FormRow,
  },

  // props
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    instance: {
      type: String,
      default: 'dev'
    }
  },

  // data
  data() {
    return {
      // choices
      services: {},

      cookieConsent: null,
      showModal: false,

      cookieNoticeClosed: null,
      consentDate: false,

      savedCookie: {},
      cookieManagerConfig: {},

      translations: defaultOptions.translations,
      styles: defaultOptions.styles,

      browserId: null,
    }
  },

  // computed
  computed: {
    availableCookieServices() {
      if (!this.cookieManagerConfig.cookieServices) return {}
      const services = this.cookieManagerConfig.cookieServices
      const keys = Object.keys(this.cookieManagerConfig.cookieServices)
      // const requiredIndex = keys.indexOf('requiredCookies')
      // keys.splice(requiredIndex, 1)
      return keys
                .filter(key => services[key] !== null)
                .reduce((acc, key) => ({
                  ...acc,
                    [key]: services[key]
                }), {})
    },

    allServices() {
      return Object.keys(this.availableCookieServices)
        .reduce((acc, key) => {
          if (this._isRequiredCookies(key)) return acc
          const services = this.availableCookieServices[key].map(item => item.id)
          acc.push(...services)
          return acc
        }, [])
    },

    servicesEnable() {
      const services = Object.keys(this.services) 
      return services.reduce((acc, key) => {
        // if (this._isRequiredCookies(key)) return acc
        const service = this.services[key]
        for (const item in service) {
          if (service[item]) acc.push(item)
        }
        return acc
      }, [])
    },

    servicesList() {
      const list = Object.keys(this.services).reduce((acc, item) => {
        acc = {
          ...acc,
          ...this.services[item]
        }
        return acc
      }, {})
      return list
    },

    apiUrl() {
      return api[this.instance]
    }
  },

  // watch: {
  //   services: {
  //     deep: true,
  //     handler: 'saveOnStore' 
  //   }
  // },

  mounted() {
    // setTimeout(this.startCookies, 1000)
    this.startCookies()
  },

  // methods
  methods: {
    startCookies() {
      this.prepareConfig()
      this.prepareTranslations()
      this.prepareStyles()
      this.prepareServices()
      this.prepareBrowserId()

      // this.initAnalytics()

      this.applySavedTrackingPermissions()
      this.initCookieConsent()
      this.verifyTagManager()

      if (!this.savedCookie && this.isCookieManagerAllowed()) {
        // setTimeout(this.showCookieManager, 6000)
        this.showCookieManager()
      }

      window.CookieManager = {
        show: this.showCookieManager,
        updateService: this.updateServicePermission,
        consent: this.cookieConsent,
        refreshPermission: this.applyTrackingPermissions
      } 
    },


    deleteCookies() {
      // this.cookieConsent.reset()
      // each cookie name

      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        const paths = [
          '/',
          '/de',
          '/en',
        ]
        // each path
        paths.map(path => {
          // with domain
          this.cookieManagerConfig.cookieDomains.map((domain) => {
            document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=${path}; domain=${domain}; secure=;`
          })
          // without domain
          document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=${path}; secure=;`
        })
      }

      for (const service in this.services) {
        this.setAllCookiesPermission(service, false)
      }

      this.saveOnApi()
        .then(res => {
          setTimeout(() => {
            location.href = window.location.pathname
          } , 300)
      })
    },

    isCookieManagerAllowed() {
      if (this.excludePage() || this.excludeHost()) return false
      // console.log('isCookieManagerAllowed true')
      return true
    },

    applySavedTrackingPermissions() {
      this.savedCookie = this.getCookies('trackingPermissions') || false
      // console.log('trackingPermissions on mount', this.savedCookie)
      if (this.savedCookie && this.savedCookie.cookieConsent) {
        for (const [key, value] of Object.entries(this.savedCookie)) {
            // console.log('key, value', key, value)
            this[key] = value
        }
      }
      this.saveOnStore()
    },
    excludeHost: function() {
      const hostsToExclude = this.cookieManagerConfig.hostsToExclude
      return hostsToExclude.includes(location.host)
    },
    excludePage: function() {
      const pagesToExclude = this.cookieManagerConfig.pagesToExclude
      return pagesToExclude.includes(location.pathname)
    },

    disableScroll() {
      const target = document.querySelector('.modal-container')
      disableBodyScroll(target)
    },
    enableScroll() {
      clearAllBodyScrollLocks()
    },
    showCookieManager: function() {
      this.disableScroll()
      this.showModal = true
      setTimeout(() => {
        document.querySelector('.checkbox-toggle__input-checkbox').focus()
      }, 100)
    },

    hideCookieManager: function() {
      this.enableScroll()
      this.showModal = false
    },

    handleConsentEvents: function() {
      if (!this.cookieManagerConfig.debug) return false

      this.cookieConsent.on('accept', (service) => {
        // console.log('service enabled: ', service)
      })
      this.cookieConsent.on('refuse', (service) => {
        // console.log('service refused: ', service)
      })
    },

    handleAccept() {
      // set all as accepted
      for (const service in this.services) {
        this.setAllCookiesPermission(service, true)
      }
      this.saveSettings()
    },

    // used for external access: 
    // Ex: <a href="javascript:window.CookieManager.updateServicePermission('mediaCookies.youtube')"
    updateServicePermission(pathService) {
      if (!pathService) return
      this.handleCheckbox(pathService)
      this.saveSettings()
      // setTimeout(this.saveSettings, 300)
    },

    handleCheckbox(pathService, reset=false) {
      reset ? 
          this.toggleAllCookiePermission(pathService) :
          this.toggleCookiePermission(pathService)

    },

    saveSettings() {
      // if (this.deleteCookiesBeforeSafe) {
      //   this.deleteCookies()
      // 
      this.consentDate = new Date()
      this.saveCookie()
      this.hideCookieManager()
      setTimeout(this.applyTrackingPermissions, 200)
      this.verifyTagManager() 
      this.saveOnStore()
      this.saveOnApi()
    },

    applyTrackingPermissions() {
      this.allServices.map(service => {
        const action = this.servicesEnable.indexOf(service) > -1 ? 'accept' : 'refuse'
        this.cookieConsent[action](service)
      })
    },

    saveCookie() {
      // set cookie
      const trackingPermissions = {
        services: this.services,
        cookieConsent: this.cookieConsent,
        consentDate: this.consentDate,
      }
      this.setCookies('trackingPermissions', trackingPermissions)
    },

    verifyTagManager() {
      if (!this.cookieManagerConfig.tagManager) return 
      const verify = ['marketingCookies', 'trackingCookies']

      const hasItemActive = verify.map(item => 
        this.services[item] ? 
          Object.values(this.services[item]).some(Boolean) : false
      ).some(Boolean)

      const action = hasItemActive ? 'accept' : 'refuse'
      this.cookieConsent[action]('googleTagManager')
    },

    setAllCookiesPermission(service, value) {
      for (const item in this.services[service]) {
        this.services[service][item] = value
      }
    },

    toggleAllCookiePermission(service) {
      const value = Object.values(this.services[service]).every(Boolean)
      for (const item in this.services[service]) {
        this.services[service][item] = !value
      }
    },

    // @params String | path : "services.mediaCookies.youtube"
    toggleCookiePermission(path) {
      var schema = this.services;  // a moving reference to internal objects within obj
      var pList = path.split('.');
      var len = pList.length;
      for(var i = 0; i < len-1; i++) {
          var elem = pList[i];
          if( !schema[elem] ) schema[elem] = {}
          schema = schema[elem];
      }
      // update value
      schema[pList[len-1]] = !schema[pList[len-1]];
    },

    setCookiePermission(permission, value) {
      this[permission] = value
    },

    getSavedCookie() {
      let trackingPermissions = {}
      try {
        trackingPermissions = this.getCookies('trackingPermissions') || {}
      } catch (e) {}
      return trackingPermissions
    },

    initCookieConsent() {
      // console.log(JSON.stringify(this.allServices), '- All cookie services ✨')
      const allServices = JSON.parse(JSON.stringify(this.allServices))
      if (this.cookieManagerConfig.tagManager) allServices.push('googleTagManager')
      this.cookieConsent = new CookieConsentApi({
        cookieName: 'cookie_consent_settings',
        cookieDuration: 365 * 2,
        cookieDomain: this.cookieManagerConfig.mainDomain ? this.cookieManagerConfig.mainDomain : null,
        services: allServices,
      })
      this.handleConsentEvents()
    },

    prepareConfig() {
      this.cookieManagerConfig = {
        ...defaultOptions,
        ...window.cookieManagerConfig,
        ...this.options
      }
      window.cookieManagerConfig = this.cookieManagerConfig
    },

    prepareTranslations() {
      const translations = this.cookieManagerConfig.translations
      const defaultTranslations = defaultOptions.translations
      this.translations = {
        introduction: {
          ...defaultTranslations.introduction,
          ...translations.introduction,
        },
        settings: {
          ...defaultTranslations.settings,
          ...translations.settings,
        },
        buttons: {
          ...defaultTranslations.buttons,
          ...translations.buttons,
        },
      }
    },

    prepareStyles() {
      this.styles = {
        ...this.cookieManagerConfig.styles
      }
    },

    prepareServices() {
      const keys = Object.keys(this.availableCookieServices)
      /* Generate Services */
      const services = keys
        .reduce((acc, service) => ({
          ...acc,
          [service]: this.availableCookieServices[service]
          .reduce((acc2, serviceItem) => ({
            ...acc2,
            [serviceItem.id]: this._isRequiredCookies(service)
            // [serviceItem.id]: true
          }), {})
        }), {})
      this.services = services
    },

    _isRequiredCookies(service) {
      return service === 'requiredCookies'
    },
  
    saveOnStore() {
      if ('$store' in this && this.$store.hasModule('cookieManager')) {
        this.$store.commit('cookieManager/setField', { 
          field: 'services',
          value: JSON.parse(JSON.stringify(this.services))
        })
      }
    },

    async prepareBrowserId() {
      await new Promise(resolve => {
        setTimeout(() => {
          const browserId = this.getCookies('browserId') || uuidv4.generate()
          this.browserId = browserId
          resolve(browserId)
        }, 100)
      })
    },

    async saveOnApi() {

      const myHeaders = new Headers();
      myHeaders.append("Origin", window.location.origin);
      myHeaders.append("Content-Type", "application/json");

      this.setCookies('browserId', this.browserId)

      const raw = JSON.stringify({
        "browser_id": this.browserId,
        "records_data": this.servicesList
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      return await fetch(this.apiUrl, requestOptions)
        .then(response => response.text())
        // .then(result => console.log('- Cookie Saved ✨', result))
        // .catch(error => console.error('- Cookie Error 🪲', error));
    },

    setCookies(cname, cvalue, exdays = 730) {
      // support json object
      if (cvalue && cvalue.constructor === Object) {
        cvalue = JSON.stringify(cvalue);
      }
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      const expires = "expires="+ d.toUTCString();
      const domain = this.cookieManagerConfig.mainDomain ? ';domain=' + this.cookieManagerConfig.mainDomain : ''
      document.cookie = encodeURIComponent(cname) + "=" + encodeURIComponent(cvalue) + ";" + expires + domain + ";path=/";
    },

    getCookies(key) {
      // eslint-disable-next-line
      let value = decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;

      if (value && value.substring(0, 1) === '{' && value.substring(value.length - 1, value.length) === '}') {
        try {
          value = JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
      return value;
    }

  },

}
</script>

<style lang="scss" scoped>
  @import 'index.scss';
</style>
